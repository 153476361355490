<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="withdraw"  label-width="100px">
      <el-form-item label="用户名" :required="true">
        {{withdraw.username}}
      </el-form-item>
      <el-form-item label="提现金额" :required="true">
        {{withdraw.amount}} 元
      </el-form-item>
      <el-form-item label="提现状态" :required="true">
        <el-radio v-model="withdraw.status" :label="withdrawStatusEnum.UNAPPROVED" :disabled="statusDisabled">未审核</el-radio>
        <el-radio v-model="withdraw.status" :label="withdrawStatusEnum.REVOKE" :disabled="statusDisabled">撤销提现</el-radio>
        <el-radio v-model="withdraw.status" :label="withdrawStatusEnum.SUCCESS" :disabled="statusDisabled">已提现</el-radio>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
    TipEnum,
    UrlEnum,
    WithdrawStatusEnum,
    request,
} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "WithdrawSave",
  data() {
    return {
      loading: false,
      id:0,
      withdrawStatusEnum:WithdrawStatusEnum,
      statusDisabled:false,
      withdraw:{
        status:'',
        amount:'',
        branchName:''
      }
    }
  },
  components: {Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
  },
  methods:{
    submit:function (){
      this.loading = true;
      let url = UrlEnum.STUDENT_WITHDRAWS;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.withdraw,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.STUDENT_WITHDRAWS + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.withdraw = res.data;
        if(this.withdraw.status != WithdrawStatusEnum.UNAPPROVED){
          this.statusDisabled = true;
        }
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
